import React, { useState, useRef } from "react"
import Fab from "@mui/material/Fab"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Stack from "@mui/material/Stack"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import CircularProgress from "@mui/material/CircularProgress"
import { Camera } from "react-camera-pro"
import axios from "axios"

var BE_API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/v1/"
    : process.env.REACT_APP_PROD_API_ENDPOINT

var CREATE_RECIPE_API_ENDPOINT = BE_API_ENDPOINT + "users/me/recipes/"
var INGREDIENT_LIST_GUESS_API_ENDPOINT = BE_API_ENDPOINT + "guess_ingredients/"

const fabStyle = {
  position: "fixed",
  bottom: "1rem",
  right: "1rem",
}

const NewRecipeButton = ({ accessToken, setRefetch }) => {
  const [formData, setFormData] = useState({
    ingredient_list: "",
    cuisine_style: "Australian",
  })
  const camera = useRef(null)
  // const [numberOfCameras, setNumberOfCameras] = useState(0)
  const [image, setImage] = useState(null)
  const [isGenerating, setIsGenerating] = useState(false) // is the backend creating a recipe?
  const [isGuessing, setIsGuessing] = useState(false) // is the backend guessing ingredients?
  const [open, setOpen] = useState(false) // is the dialog modal open?

  const submitFormData = async () => {
    if (accessToken) {
      axios
        .post(CREATE_RECIPE_API_ENDPOINT, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async (response) => {
          // console.log(response.data);
          // setRecipeData(response.data);
          handleClose()
        })
        .catch((error) => {
          // console.error('Error fetching data:', error);
          handleClose()
          switch (error.response && error.response.status) {
            case 400:
              alert("Sorry, pantrybot couldn't generate a recipe! Please try again.") // alert(error.response.data.detail) - don't give the user details
              break
            default:
              alert("Sorry, pantrybot couldn't generate a recipe! Please try again.")
          }
        })
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
    setFormData({
      ingredient_list: "",
      cuisine_style: "Australian",
    })
    setImage(null)
    // setRecipeData({})
  }

  const handleClose = () => {
    setRefetch(true)
    setOpen(false)
    setIsGenerating(false)
    setIsGuessing(false)
  }

  const handleCancel = () => {
    setOpen(false)
    setIsGenerating(false)
    setIsGuessing(false)
  }

  const handleSubmit = async () => {
    if (formData.ingredient_list.length <= 5) {
      alert("Ingredient list should be at least 6 chars!")
    } else {
      setIsGenerating(true)
      // send form to recipe create endpoint
      await submitFormData()
    }
  }

  // from https://gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf
  // resize everything to 512x512 as expected by openAI GTP4 vision 'low'
  //  https://platform.openai.com/docs/guides/vision?lang=node
  function resizeImage(base64Str, maxWidth = 512, maxHeight = 512) {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  const handleGuessComplete = () => {
    setIsGuessing(false)
  }

  const submitImageGuess = async (resized) => {
    if (accessToken) {
      axios
        .post(INGREDIENT_LIST_GUESS_API_ENDPOINT, {"image_str": resized}, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async (response) => {
          // console.log(response.data);
          setFormData((prevData) => ({...prevData, ingredient_list: response.data.ingredient_list}));
          handleGuessComplete()
        })
        .catch((error) => {
          // console.error('Error fetching data:', error);
          handleGuessComplete()
          alert("Sorry, pantrybot couldn't guess those ingredients! Please try again or enter manually.")
        })
    }
  }

  const handleIngredientImage = async () => {
    setIsGuessing(true)
    resizeImage(image).then(async (resized) => {
      await submitImageGuess(resized)
    })
  }

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
  }

  if (isGuessing) {
    return (
      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Guessing Ingredients...</DialogTitle>
          <DialogContent
            sx={{
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2}>
              <img sx={{width: "50%"}} src={image} alt="ingredients" />
              <CircularProgress />
            </Stack>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  } else if (isGenerating) {
    return (
      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Generating Recipe...</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2}>
              <DialogContentText>{formData.ingredient_list}</DialogContentText>
              <CircularProgress />
            </Stack>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Fab
          variant="extended"
          color="primary"
          sx={fabStyle}
          onClick={handleClickOpen}
        >
          <AddIcon sx={{ mr: 1 }} />
          New Recipe
        </Fab>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New Recipe</DialogTitle>
          <DialogContent>
            <Stack spacing={1}>
              <DialogContentText>
                Ask pantrybot to make you a new recipe! Take a picture of your
                ingredients, or fill in the box below:
              </DialogContentText>

              {image === null && (
                <>
                  <Camera
                    ref={camera}
                    facingMode="environment"
                    // numberOfCamerasCallback={setNumberOfCameras}
                    aspectRatio={1}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setImage(camera.current.takePhoto())}
                  >
                    Take photo
                  </Button>
                  {/* <Button
                    hidden={numberOfCameras <= 1}
                    onClick={() => {
                      camera.current.switchCamera()
                    }}
                  >
                    Switch Camera
                  </Button> */}
                </>
              )}

              {image !== null && (
                <>
                  <img src={image} alt="ingredients" />
                  <Button variant="outlined" onClick={() => handleIngredientImage()}>
                    Guess Ingredients
                  </Button>
                  <Button onClick={() => setImage(null)}>Re-take photo</Button>
                </>
              )}
              <TextField
                required
                margin="dense"
                id="ingredient_list"
                label="Ingredients"
                fullWidth
                type="text"
                variant="outlined"
                value={formData.ingredient_list}
                onChange={(e) => handleInputChange(e, "ingredient_list")}
              />
              <FormControl fullWidth>
                <FormLabel id="cuisine-radio-group">Cuisine Style</FormLabel>
                <RadioGroup
                  aria-labelledby="cuisine-radio-group"
                  name="controlled-radio-buttons-group"
                  value={formData.cuisine_style}
                  onChange={(e) => handleInputChange(e, "cuisine_style")}
                >
                  <FormControlLabel
                    value="African"
                    control={<Radio />}
                    label="African"
                  />
                  <FormControlLabel
                    value="Asian"
                    control={<Radio />}
                    label="Asian"
                  />
                  <FormControlLabel
                    value="Australian"
                    control={<Radio />}
                    label="Australian"
                  />
                  <FormControlLabel
                    value="European"
                    control={<Radio />}
                    label="European"
                  />
                  <FormControlLabel
                    value="North American"
                    control={<Radio />}
                    label="North American"
                  />
                  <FormControlLabel
                    value="South American"
                    control={<Radio />}
                    label="South American"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Let's cook!
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default NewRecipeButton
