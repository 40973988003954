import { LogoutButton } from "@userfront/toolkit/react"

function Account({ accountInfo }) {
  return (
    <div>
      <h2>Account</h2>
      <p>{accountInfo.email}</p>
      <LogoutButton />
    </div>
  )
}

export default Account
