import { PasswordResetForm } from "@userfront/toolkit/react"

function PasswordReset() {
  return (
    <div>
      <h2>Password Reset</h2>
      <PasswordResetForm />
    </div>
  )
}

export default PasswordReset
