import React from "react"
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-router-dom"

const NavBar = ({ isAuthenticated }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const beerHandler = () => {
    window.open("https://ko-fi.com/luke226")
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography href="/" variant="h6" component="div" sx={{ flexGrow: 1 }}>
          PantryBot
        </Typography>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem component={Link} to="/" onClick={handleMenuClose}>
            Home
          </MenuItem>
          {!isAuthenticated && (
            <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
              Log In
            </MenuItem>
          )}
          {!isAuthenticated && (
            <MenuItem component={Link} to="/signup" onClick={handleMenuClose}>
              Sign Up
            </MenuItem>
          )}
          {!isAuthenticated && (
            <MenuItem component={Link} to="/passwordreset" onClick={handleMenuClose}>
              Reset Password
            </MenuItem>
          )}
          {isAuthenticated && (
            <MenuItem component={Link} to="/account" onClick={handleMenuClose}>
              Account
            </MenuItem>
          )}
        </Menu>
        {process.env.NODE_ENV === "development" && <b>TEST</b>}
        <Button color="inherit" variant="outlined" onClick={beerHandler}>
          Runs on Beer
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
