import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { Container } from "@mui/material"

import Userfront from "@userfront/toolkit/react"

import NavBar from "./components/NavBar"
import Home from "./components/Home"
import Login from "./components/Login"
// import LogOut from "./components/LogOut"
import SignUp from "./components/SignUp"
import Account from "./components/Account"
import PasswordReset from "./components/PasswordReset"

Userfront.init("8nwymmdn")

const App = () => {
  var isAuthenticated = Userfront.tokens.accessToken || false

  return (
    <Router>
      <NavBar isAuthenticated={isAuthenticated} />
      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={<Home isAuthenticated={isAuthenticated} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          {isAuthenticated && (
            <Route
              path="/account"
              element={<Account accountInfo={Userfront.user} />}
            />
          )}
        </Routes>
      </Container>
    </Router>
  )
}

export default App
