import { LoginForm } from "@userfront/toolkit/react"

function Login() {
  return (
    <div>
      <LoginForm />
    </div>
  )
}

export default Login
