import React, { useState } from "react"
import Userfront from "@userfront/toolkit/react"
import { Link } from "react-router-dom"
import UserRecipes from "./UserRecipes"
import NewRecipeButton from "./NewRecipeButton"

function Home({ isAuthenticated }) {
  const [refetch, setRefetch] = useState(false)
  if (isAuthenticated) {
    return (
      <div>
        <h2>Hello Chef!</h2>
        <p>
          Simply provide a list of ingredients, and PantryBot will think of a
          delicious recipe you can prepare. PantryBot will also estimate nutritional
          content and the number of servings, based on the quantities of the
          ingredients provided.
        </p>
        <p>
          Version 3 (coming soon) will provide you with helpful images demonstrating the recipe.
        </p>
        <UserRecipes
          accessToken={Userfront.tokens.accessToken}
          refetch={refetch}
          setRefetch={setRefetch}
        />
        <NewRecipeButton
          accessToken={Userfront.tokens.accessToken}
          setRefetch={setRefetch}
        />
      </div>
    )
  }
  return (
    <div>
      <h2>Welcome!</h2>
      <p>PantryBot is an AI powered recipe generator!</p>
      <p>
        Simply provide a list of ingredients, and PantryBot will think of a delicious
        recipe you can prepare. PantryBot will also estimate nutritional content and
        the number of servings, based on the quantities of the ingredients provided.
      </p>
      <p>In order to use PantryBot, you must create an account.</p>
      <h3>
        Please {<Link to="/signup">Sign up</Link>} or{" "}
        {<Link to="/login">Log in</Link>}
      </h3>
    </div>
  )
}

export default Home
