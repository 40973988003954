import { SignupForm } from "@userfront/toolkit/react"

function SignUp() {
  return (
    <div>
      <SignupForm />
    </div>
  )
}

export default SignUp
