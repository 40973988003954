import * as React from "react"
import { styled } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import CardMedia from "@mui/material/CardMedia"
import Collapse from "@mui/material/Collapse"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import axios from "axios"


var BE_API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/v1/"
    : process.env.REACT_APP_PROD_API_ENDPOINT

var DELETE_USER_RECIPES_API_ENDPOINT = BE_API_ENDPOINT + "users/me/recipes/"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return (
    <Button {...other} endIcon={<ExpandMoreIcon />} variant="outlined">
      Show method
    </Button>
  )
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function RecipeCard({ recipe, accessToken, setRefetch }) {
  const [expanded, setExpanded] = React.useState(false)
  const [ imgHeight, setImgHeight ] = React.useState(100)
  const handleExpandClick = () => {
    setExpanded(!expanded)
    setImgHeight(!expanded ? "100%" : 100);

  }

  // render \n as <p></p>
  function NewlineText(props) {
    const text = props.text
    const newText = text.split("\n").map((str, idx) => (
      <Typography key={idx} variant="body">
        {str}
        <br />
      </Typography>
    ))
    return newText
  }

  const FormatDateTime = ({ datetimeString }) => {
    // Splitting the string between date and time
    const [date, time] = datetimeString.split("T")

    // Assuming 03 is the month and 01 is the day – otherwise, those could be swapped
    const [year, month, day] = date.split("-")

    // Added slashes and the space before the time
    return `${day}/${month}/${year} ${time}`
  }

  const handleDeleteClick = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this recipe?');
    if (!confirmed) {
        return; // Exit early if the user did not confirm
    }
      try {
        if (accessToken) {
          const response = await axios.delete(DELETE_USER_RECIPES_API_ENDPOINT+recipe.id, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        }
      } catch (error) {
        console.error("Error deleting recipe:", error)
      }
      setRefetch(true)
  }

  return (
    <Card sx={{ maxWidth: "95%" }} variant="outlined">
      <CardHeader
        title={recipe.title}
        subheader={<FormatDateTime datetimeString={recipe.created_at_utc} />}
      />
      <CardContent>
      {/* <CardMedia
          component="img"
          height={imgHeight}
          hidden={recipe.presign_response ? false : true}
          src={recipe.presign_response ? recipe.presign_response.presigned_url : ''}
          // image={recipe.method_image_key}
          // alt={recipe.title}
        /> */}
        <Typography variant="body2" color="text.secondary">
          <b>Ingredients: </b>
          {recipe.ingredient_list}
        </Typography>
        
        <Typography variant="caption" color="text.secondary">
          <b>Nutrition estimate: </b>
          {recipe.kcal} kcal, Protein {recipe.protein_grams}g, Carbohydrate{" "}
          {recipe.carbohydrate_grams}g, Fat {recipe.fat_grams}g
        </Typography>
        <Typography variant="caption" color="text.secondary">
          <div>
            <b>Servings: </b>
            {recipe.servings_num}
          </div>
        </Typography>
      </CardContent>
      <CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <Button color="error" onClick={handleDeleteClick}>Delete</Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>Instructions:</Typography>
          <Typography variant="body">
            <NewlineText text={recipe.method} />
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}
