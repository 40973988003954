import React, { useState, useEffect } from "react"
import axios from "axios"
import CircularProgress from "@mui/material/CircularProgress"
import RecipeCard from "./RecipeCard"
import Stack from "@mui/material/Stack"

var BE_API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/v1/"
    : process.env.REACT_APP_PROD_API_ENDPOINT

var GET_USER_RECIPES_API_ENDPOINT = BE_API_ENDPOINT + "users/me/recipes/"

const UserRecipes = ({ accessToken, refetch, setRefetch }) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [cachedResponses, setCachedResponses] = useState({});

  const getRecipeImageURLS = async (data) => {
    const updatedData = await Promise.all(
       data.map(async (result, index) => {
        let presign_response = cachedResponses[result.id];
        if (!presign_response) 
         {presign_response = await axios.post(GET_USER_RECIPES_API_ENDPOINT + "image_url", {recipe_id:result.id}, {
           headers: {
             Authorization: `Bearer ${accessToken}`,
           }
         });
         setCachedResponses(prevState => ({...prevState, [result.id]: presign_response.data}));
        }
         // Include presign_response as a subfield in the result object
         return { ...result, presign_response: presign_response.data };
       })
    );
    return updatedData;
   };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accessToken) {
          const response = await axios.get(GET_USER_RECIPES_API_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          // const mutated_data = await getRecipeImageURLS(response.data)
          setData(response.data)
          setIsLoading(false)
          setRefetch(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [accessToken, refetch, setRefetch])

  if (isLoading === true) {
    return (
      <div>
        <h3>Your Recipes</h3>
        <CircularProgress />
      </div>
    )
  } else {
    if (data.length === 0) {
      return (
        <div>
          <h3>No recipes yet! Try creating one using the button below.</h3>
        </div>
      )
    } else {
      return (
        <div>
          <h3>Your Recipes</h3>
          <Stack spacing={1}>
            {data.map(
              (result, index) =>
                result.title && <RecipeCard recipe={result} key={index} accessToken={accessToken} setRefetch={setRefetch}/>,
            )}
            <br />
            <br />
            <br />
            <br />
          </Stack>
        </div>
      )
    }
  }
}

export default UserRecipes
